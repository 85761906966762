<template>
  <div class="main-box">
    <MainBodyHeader title="مدیریت زمین های من">
      <CRow class="w-100" align-vertical="center">
        <CCol col="auto" class="show-list-state y-center-g-5" >
          <span>{{
              !archiveFarms ? "نمایش زمین های آرشیو شده" : "نمایش زمین های فعال"
            }}</span>
          <CSwitch
            variant="3d"
            size="sm"
            color="success"
            :checked.sync="archiveFarms"
          />
        </CCol>
        <CCol col="auto" class="text-muted">مجموع مساحت تمام زمین های شما:</CCol>
        <CCol col="auto" class="text-danger">{{ sumAreas.toFixed(2) }} هکتار</CCol>
        <CCol v-if="userType == 'Farmer'">
          <CDropdown placement="bottom-end">
            <template #toggler>
              <CButton color="primary" class="y-center-g-5" style="margin-right: auto">
                <span>ثبت زمین</span>
                <font-awesome-icon icon="chevron-down" />
              </CButton>
            </template>
            <div class="py-1">
              <CDropdownItem @click="$router.push({ name: 'ثبت اطلاعات زمین با فایل' })">
                <i class="fa fa-file-image-o mx-2" aria-hidden="true"></i> ثبت زمین با فایل KML
              </CDropdownItem>
              <CDropdownItem @click="$router.push({ name: 'ثبت اطلاعات زمین با نقشه' })">
                <i class="fa fa-map-o mx-2" aria-hidden="true"></i> ثبت زمین با نقشه
              </CDropdownItem>
              <CDropdownItem @click="$router.push({ name: 'ثبت اطلاعات زمین با نقشه' })">
                <i class="fa fa-archive mx-2" aria-hidden="true"></i> ثبت زمین های آرشیو
              </CDropdownItem>
            </div>
          </CDropdown>
        </CCol>
      </CRow>
    </MainBodyHeader>

    <vue-element-loading :active="loading" spinner="bar-fade-scale" color="var(--secondary)" />

    <CRow class="farmListBoxViewer">
      <CCol col="12">
        <list-with-ads :loading="loading" :adsCount="1">
          <div v-for="(item, index) in farmsList" :key="index">
            <farmItem
              :itemModel="item" 
              @deleteFarmItem="deleteFarm"
              @viewDetails="getFarmInfo"
              @detailArchiveItem="deleteArchive"
              @reloadList="getFarmsList"
            />
          </div>
        </list-with-ads>
      </CCol>
    </CRow>

    <vueModal
      title="جزئیات زمین"
      v-model="selectedFarm"
      wrapper-class="animate__animated animate__faster"
      in-class="animate__fadeIn"
      out-class="animate__fadeOut"
      :enable-close="true"
      modalStyle="max-width: 60vw; width:unset; min-width: 40vw;"
    >
      <detail :key="selectedFarmItem" :farmId="selectedFarmItem" />
    </vueModal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import polyGonStructure from "../../store/data/polygonFeature.json";
import * as turf from "@turf/turf";
import detail from "./farmDetail";
import farmItem from "./farmItem";
import { getCurrentUser } from "../../utils/index";
import MainBodyHeader from "../../components/mainHeader.vue";
import ListWithAds from "../../components/list-with-ads.vue";

export default {
  name: "FarmsList",
  components: {
    ListWithAds,
    MainBodyHeader,
    detail,
    farmItem,
  },
  data() {
    return {
      farmsList: [],
      details: [],
      sumAreas: 0,
      loading: false,
      loadingText: "در حال دریافت اطلاعات ...",
      selectedFarmItem: 0,
      selectedFarm: false,
      userType: null,
      archiveFarms: false,
    };
  },
  watch: {
    archiveFarms: function () {
      this.getFarmsList();
    },
  },
  methods: {
    ...mapActions("farm", ["Gets", "Delete", "DeleteArchive"]),
    async getFarmsList() {
      this.farmsList = [];
      this.sumAreas = 0;
      this.loading = true;
      this.loadingText = "در حال دریافت اطلاعات...";
      let result = await this.Gets({
        farmName: "",
        farmerPhone: "",
        archive: this.archiveFarms,
      });
      if (result) {
        this.farmsList = result.data.map((item, id) => {
          let polygon = item.area.substring(item.area.indexOf("("), item.area.indexOf(")"));
          polygon = polygon.replaceAll("(", "");
          polygon = polygon.replaceAll(")", "");
          polygon = polygon.split(", ");
          polygon = polygon.map((item) => item.split(" "));
          const newArray = [polygon];
          polyGonStructure.features[0].geometry.coordinates = newArray;
          let farmArea = turf.area(polyGonStructure.features[0].geometry);
          item.farmArea = Intl.NumberFormat("fa").format((farmArea / 10000).toFixed(2));
          this.sumAreas += farmArea / 10000;
          return { ...item, id };
        });
      }
      this.loading = false;
    },
    async deleteFarm(canDelete, id) {
      if (canDelete) {
        if (id > 0) {
          this.$confirm({
            message: `زمین مورد نظر حذف شود؟`,
            button: {
              no: "خیر",
              yes: "بله",
            },
            callback: async (confirm) => {
              if (confirm) {
                this.loading = true;
                this.loadingText = "در حال حذف اطلاعات زمین ...";
                let result = await this.Delete({ id: id });
                this.loading = false;
                if (result.succeeded) {
                  await this.getFarmsList();
                  this.$notify({
                    title: "پیام سیستم",
                    text: "زمین مورد نظر حذف شد <br /><br />",
                    type: "success",
                  });
                } else {
                  this.$notify({
                    title: "پیام سیستم",
                    text: `${result.message}  <br /><br />`,
                    type: "error",
                  });
                }
              }
            },
          });
        } else {
          this.$notify({
            title: "پیام سیستم",
            text: "شناسه زمین شما معتبر نیست <br /><br />",
            type: "error",
          });
        }
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: "امکان حذف اطلاعات زمین وجود ندارد <br /><br />",
          type: "info",
        });
      }
    },
    async deleteArchive(id) {
      if (this.archiveFarms) {
        if (id > 0) {
          this.$confirm({
            message: `زمین مورد نظر از آرشیو خارج شود؟`,
            button: {
              no: "خیر",
              yes: "بله",
            },
            callback: async (confirm) => {
              if (confirm) {
                this.loading = true;
                this.loadingText = "در حال ارسال اطلاعات ...";
                let result = await this.DeleteArchive({ id: id });
                this.loading = false;
                if (result.succeeded) {
                  await this.getFarmsList();
                  this.$notify({
                    title: "پیام سیستم",
                    text: "زمین مورد نظر از آرشیو خارج شد <br /><br />",
                    type: "success",
                  });
                } else {
                  this.$notify({
                    title: "پیام سیستم",
                    text: `${result.message}  <br /><br />`,
                    type: "error",
                  });
                }
              }
            },
          });
        } else {
          this.$notify({
            title: "پیام سیستم",
            text: "شناسه زمین شما معتبر نیست <br /><br />",
            type: "error",
          });
        }
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: "امکان حذف اطلاعات زمین وجود ندارد <br /><br />",
          type: "info",
        });
      }
    },
    async getFarmInfo(farmId) {
      this.selectedFarmItem = farmId;
      this.selectedFarm = true;
    },
  },
  mounted() {
    this.userType = getCurrentUser().type;
    this.getFarmsList();
  },
};
</script>

<style>
.main-card {
  justify-content: space-between;
}
.vm-content,
.vm {
  overflow: visible !important;
}
</style>
