<template>
  <div class="main-card h-100 gap-20 d-flex flex-column">
    <CRow class="bodyItem gap-10">
      <CCol col="9">
        <CRow>
          <!-- Display image and title -->
          <CCol col="12" class="bodyItemValue y-center-g-5">
            <img :src="imageSrc" class="productImage rounded" alt="image" />
            <h4>{{ title }}</h4>
          </CCol>

          <!-- Dynamic Fields -->
          <CCol
            col="12"
            v-for="(field, index) in fields"
            :key="index"
            class="bodyItemValue"
          >
            <span>{{ field.label }} :</span>
            <span v-if="field.slot">
              <!-- Allow for custom slot overrides for fields like status -->
              <slot :name="field.slot"></slot>
            </span>
            <span v-else>{{ field.value }}</span>
          </CCol>

          <hr class="m-2 w-100" />

          <!-- Address Field -->
          <CCol col="12" class="bodyItemValue" v-if="address">
            <CIcon name="cilMap" class="ml-2"></CIcon>
            <span>{{ address }}</span>
          </CCol>
        </CRow>
      </CCol>
      <CCol col="2">
        <!-- Operations/Actions -->
        <CRow class="gap-10">
          <slot name="actions"></slot>
          <!-- Actions can be passed via slot -->
        </CRow>
      </CCol>
    </CRow>
  </div>
</template>

<script>
export default {
  props: {
    imageSrc: {
      type: String,
      default: "https://picsum.photos/200", // Default image
    },
    title: {
      type: String,
      required: true,
    },
    fields: {
      type: Array,
      required: true, // Array of fields with {label, value, slot}
    },
    address: {
      type: String,
      default: null, // Optional address
    },
  },
};
</script>

<style scoped>
.bodyItemValue {
  width: 100%;
}
.productImage {
  width: 50px;
  height: 50px;
  object-fit: contain;
}
</style>
